<template>
  <div :class="`m-3 wrapper  wrapper--${bu}`">
    <Loading v-if="loading" />
    <div v-else>
      <h2 class="text-center">Vooruit betaald</h2>
      <div class="flex _row spaceevenly">
        <table :class="`m-2 table__border--${bu}`">
          <thead :class="`table__head--${bu}`">
            <tr>
              <th class="table__head--text">Naam</th>
              <th class="table__head--text">Status</th>
              <th class="table__head--text">Aantal</th>
              <th class="table__head--text">Bedrag</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, key) in payed_not_recieved"
              :key="key"
              class="table__row--color"
            >
              <td class="table__cell--default">{{ item.naam }}</td>
              <td class="table__cell--default">{{ item.status }}</td>
              <td class="table__cell--default">{{ item.aantal }}</td>
              <td class="table__cell--default">€ {{ item.Bedrag }}</td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="table__foot">
              <th class="table__foot--padding">Totaal:</th>
              <th class="table__foot--padding"></th>
              <th class="table__foot--padding">
                {{ payed_not_recieved_sum.aantal }}
              </th>
              <th class="table__foot--padding">
                € {{ payed_not_recieved_sum.bedrag }}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";

export default {
  props: ["bu"],
  components: { Loading },
  data: () => ({
    loading: true,
    payed_not_recieved: null,
    payed_not_recieved_sum: null,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.loading = true;
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      request(`budget-payed-not-recieved/${bu}`, "GET").then(
        ({ payed_not_recieved, payed_not_recieved_sum }) => {
          this.payed_not_recieved = payed_not_recieved;
          this.payed_not_recieved_sum = payed_not_recieved_sum;
          this.loading = false;
        }
      );
    },
  },
};
</script>
